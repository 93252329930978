.loading-spinner {
  border: 2px solid #3f51b5;
  border-top: 2px solid #f44336;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}


h3 {
  /* Add styles for the text here */
  color: #3f51b5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
