@import url("https://fonts.googleapis.com/css?family=Roboto");

/* Global Styles */
:root {
  --gh-pattern-1: #fff4f9;
  --gh-pattern-2: #ffebf1;
  --gh-pattern-3: #ffe3d7;
  --gh-pattern-4: #ffe2b1;
  --gh-pattern-5: #ffea8b;

  --gh-green-1: #b9f3cd;
  --gh-green-2: #96ed85;
  --gh-green-3: #73e79c;
  --gh-green-4: #51e183;
  --gh-green-5: #2edc6b;
  --gh-green-6: #21c058;
  --gh-green-7: #1b9d48;
  --gh-green-8: #157a38;
  --gh-green-9: #0f5728;
  --gh-green-10: #062310;

  --gh-blue-0: #e2f4fb;
  --gh-blue-1: #c7eaf9;
  --gh-blue-2: #a2ddf6;
  --gh-blue-3: #7dcff2;
  --gh-blue-4: #58c1ee;
  --gh-blue-5: #33b4eb;
  --gh-blue-6: #16a3df;
  --gh-blue-7: #1287ba;
  --gh-blue-8: #0f6c95;
  --gh-blue-9: #0b4f6c;
  --gh-blue-10: #07364a;

  --gh-grey-01: #f7f8fc;
  --gh-grey-0: #f1f0f0;
  --gh-grey-1: #e7e6e6;
  --gh-grey-2: #adadad;
  --gh-grey-3: #999999;
  --gh-grey-4: #757575;
  --gh-grey-5: #666666;

  --gh-pink-1: #fff0f7;
  --gh-pink-2: #efa9ca;
  --gh-pink-3: #e986b4;
  --gh-pink-4: #e3649f;
  --gh-pink-5: #da3582;

  --gh-red-1: #e22452;
  --gh-red-2: #ce2727;
  --gh-red-3: #bc2424;

  --gh-yellow-1: #ffedc2;
  --gh-yellow-2: #f6e4be;
  --gh-yellow-3: #ffd470;
  --gh-yellow-4: #ffc847;

  --gh-orange-1: #ffbc1f;
  --gh-orange-2: #f5ab00;
  --gh-orange-3: #f9aa81;

  --gh-brown-1: #a37200;
  --gh-brown-2: #7a5600;
  --gh-brown-3: #523900;
  --gh-brown-4: #823d40;

  --transition: all 0.3s linear;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  background-color: var(--gh-grey-01);
  color: #333;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .container {
  width: 1024px;
  height: 100%;
  text-align: left;
} */

.table {
  table-layout: fixed;
  width: 100%;
  border: 1px solid var(--gh-grey-2);
  border-collapse: collapse;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 50px;
  font-size: 13px;
}
.header {
  position: sticky;
  top: 59px;
  z-index: 50;
  background-color: var(--gh-grey-0);

}
.thead {
  background-color: var(--gh-grey-0);
  border: 1px solid var(--gh-grey-2);
}
/*
.td {
  padding: 0 20px;
  border: 1px solid var(--gh-grey-4);
}

.th {
  padding: 10px 20px;
}

.thh {
  width: 190px;
  border-top: 1px solid var(--gh-grey-2);
  padding: 10px 20px;
}
.tr {
  width: 190px;
  height: 40px;
  border-top: 0.025rem solid var(--gh-grey-1);
} */
button {
  background-color: transparent;
  color: var(--gh-blue-5);
  gap: 8px;
  border: none;
  cursor: pointer;
}

.button-td {
  padding: 0 10px;
  width: 2px;
}

.Toastify {
  display: none;
}
