.spinner {
    margin: 100px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    position: relative;
  }
  
  .spinner-text {
    font-size: 1.2rem;
    animation: opaque 2000ms ease-in-out infinite;
  }
  
  .spinner-component {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 10px solid transparent;
    mix-blend-mode: hue;
  }
  
  .red {
    border-top: 10px solid red;
    animation: load 1300ms linear infinite;
    z-index: -1;
  }
  
  .green {
    border-top: 10px solid green;
    animation: load 1600ms ease-out infinite;
    z-index: -3;
  }
  
  .blue {
    border-top: 10px solid blue;
    animation: load 1900ms ease-in infinite;
    z-index: -2;
  }
  
  @keyframes load {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes opaque {
    0%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.1;
    }
  }
  